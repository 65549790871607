import React, { ChangeEvent, createRef, useState } from "react";
interface OtpFieldProps {
  otpEnterDone: (otp: string) => void;
}

const OtpField: React.FC<OtpFieldProps> = ({ otpEnterDone }) => {
  const [otp, setOtp] = useState<string>("");
  const otpInputRefs = Array.from({ length: 6 }, () =>
    createRef<HTMLInputElement>()
  );

  const handleOtpChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setOtp((prevOtp) => {
      const otpArray = prevOtp.split("");
      otpArray[index] = value;
      return otpArray.join("");
    });

    if (value.length === 1 && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current?.focus();
    } else if (value.length === 0 && index > 0) {
      otpInputRefs[index - 1].current?.focus();
    }
    if (value.length === 1 && index === otpInputRefs.length - 1) {
      // get the last field value
      const otp = otpInputRefs.map((ref) => ref.current?.value).join("");
      otpEnterDone(otp);
    }
  };
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="flex">
          {Array.from({ length: 6 }, (_, index) => (
            <input
              key={index}
              ref={otpInputRefs[index]}
              type="text"
              className="w-12 h-12 border border-gray-300 rounded text-3xl mx-1 text-center focus:border-kGreen-light"
              maxLength={1}
              value={otp[index] || ""}
              onChange={(e) => handleOtpChange(index, e)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default OtpField;

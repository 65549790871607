import { GoCreditCard } from "react-icons/go";
import { LuBadgeDollarSign, LuWallet } from "react-icons/lu";
import { OfferCard } from "./components/offerCard";
export function SheetContent() {
  return (
    <section className="mx-4 my-2">
      <div className="h-1"></div>
      <div className="flex items-center border shadow-xl p-2  rounded-xl">
        <div>
          <LuBadgeDollarSign size={25} />
          {/* <SiDiscourse size={25} /> */}
        </div>
        <div className="w-4"></div>
        <div className="flex flex-col space-y-1">
          <p>Welcome to Kube </p>
          <p className="text-xs text-gray-500">
            Tour your credit reports, insights, offers and more
          </p>
        </div>
      </div>

      <div className="h-6"></div>

      {/* Offer */}
      <p className="font-bold">Your Offer</p>
      <div className="h-4"></div>

      <section className="flex overflow-x-auto space-x-6 scroll-hide w-full pl-2">
        <OfferCard
          offerTitle={"Credit Card Offers"}
          label={"Credit Card"}
          contentText={"Find out which credit cards you qualify"}
          role="offer"
          icons={
            <p className=" absolute top-3 right-3 flex ">
              <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center">
                <GoCreditCard size={25} />
                {/* <BsFillCreditCardFill size={25} /> */}
              </div>
            </p>
          }
        />
        <OfferCard
          offerTitle={"Loan Offers"}
          label={"Car Loan"}
          contentText={"Get your loan for upcoming purchase "}
          role="offer"
          icons={
            <p className=" absolute top-3 right-3 flex ">
              <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center">
                <LuWallet size={25} />
              </div>
            </p>
          }
        />
      </section>

      {/* Whats new */}

      <div className="h-6"></div>
      <p className="font-bold">Whats New</p>
      <div className="h-4"></div>

      <OfferCard
        offerTitle={"Considering an upgraded current account?"}
        label={"Current Accounts"}
        contentText={
          "Open a current account with Kube and enjoy bonuses, cashback, and attractive linked savings options "
        }
        icons={null}
        role="new"
        hint={
          <li className="list-disc font-light text-gray-500 text-sm">
            Create a new account
          </li>
        }
      />

      {/* Card Provider */}

      <div className="h-6"></div>
      <p className="font-bold">
        Suitable for individuals within your score range
      </p>
      <div className="h-4" />

      <div className="bg-teal-50 rounded-lg border shadow-lg">
        <div className="flex flex-col p-4 border-b border-b-gray-400 space-y-3">
          <div>
            <p className="max-w-[60vw] font-bold">Monzo</p>
            <p className="text-sm text-gray-500">
              25,000 Bonus points T&C apply
            </p>
          </div>
        </div>
        <div className="flex flex-col p-4 border-b border-b-gray-400 space-y-3">
          <div>
            <p className="max-w-[60vw] font-bold">HSBC UK</p>
            <p className="text-sm text-gray-500">
              Up to £250 cashback, T&C company
            </p>
          </div>
        </div>
      </div>
      <div className="h-16" />
    </section>
  );
}

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxiosManual } from "../../../components/hooks/useAxios";
import AlertPrimary from "../../../modules/loan/components/AlertPrimary";
import { AsyncMutationRequest } from "../../../modules/shared/AsyncValue";
import useBoundStore from "../../../state/store";
import OtpField from "./OtpField";

export const OtpVerificationPageRoute = "otp-verification";
interface OtpVerificationPageProps {}

const OtpVerificationPage: React.FC<OtpVerificationPageProps> = () => {
  const { trigger, ...restProps } = useAxiosManual("/auth/user/otp/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-app-key": "d6e3f7d93sd56dfhfgha15c1a9sdfwe4",
    },
  });
  const nav = useNavigate();
  const [searchParamas, setSearchParamas] = useSearchParams();
  const setUser = useBoundStore((state) => state.setUser);

  const handleVerifyLogin = (otp: string) => {
    trigger(
      {
        rconfig: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-app-key": "d6e3f7d93sd56dfhfgha15c1a9sdfwe4",
          },
          data: {
            phone: searchParamas.get("phone"),
            hash: searchParamas.get("hash"),
            code: otp,
          },
        },
      },
      {
        onSuccess: (data: any) => {
          setUser(data);
          nav("/home");
        },
      }
    );
  };
  return (
    <>
      <section className="flex flex-col justify-center items-center space-y-2  min-h-[100vh]">
        <AsyncMutationRequest<any, any>
          res={restProps}
          onIdle={
            <>
              <svg
                width="140"
                height="140"
                viewBox="0 0 140 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1967_10281)">
                  <path
                    d="M81.1238 18.2324H81.0543H43.1075H43.038C35.9128 18.2324 30.1367 24.0085 30.1367 31.1337C30.1367 35.6827 30.1367 103.259 30.1367 107.665C30.1367 109.544 30.1367 124.316 30.1367 126.163C30.1367 133.288 35.9128 139.065 43.038 139.065H81.1238C88.249 139.065 94.0251 133.289 94.0251 126.163C94.0251 99.3274 94.0251 78.5689 94.0251 55.3871V31.1335C94.0251 24.0085 88.249 18.2324 81.1238 18.2324Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M37.382 126.163C37.382 123.971 37.382 109.667 37.382 107.665C37.382 85.1855 37.382 55.0787 37.382 31.1337C37.382 24.0085 43.1581 18.2324 50.2833 18.2324H43.1075H43.038C35.9128 18.2324 30.1367 24.0085 30.1367 31.1337C30.1367 35.6827 30.1367 103.259 30.1367 107.665C30.1367 109.544 30.1367 124.316 30.1367 126.163C30.1367 133.288 35.9128 139.065 43.038 139.065H50.2833C43.1581 139.064 37.382 133.288 37.382 126.163Z"
                    fill="#DDDDDD"
                  />
                  <path
                    d="M86.8032 126.09C86.8032 129.267 84.2274 131.843 81.0498 131.843H43.1128C39.9352 131.843 37.3594 129.267 37.3594 126.09C37.3594 123.819 37.3594 109.617 37.3594 107.591C37.3594 96.7063 37.3594 69.6122 37.3594 58.5156V30.9307C37.3594 28.018 39.5772 25.8863 42.3854 25.5054C42.6228 25.4756 42.8629 25.4551 43.1079 25.4551H46.5313C47.5682 25.4551 48.4587 26.2694 48.47 27.3063C48.47 27.3142 48.4702 27.3224 48.4702 27.3303V28.7913C48.4702 30.623 49.9689 32.1218 51.8007 32.1218C53.0891 32.1218 73.0525 32.1218 72.3618 32.1218C74.1936 32.1218 75.6923 30.623 75.6923 28.7913V27.3303C75.6923 27.3224 75.6923 27.3142 75.6926 27.3063C75.7041 26.2694 76.5944 25.4551 77.6312 25.4551H81.0547C81.3 25.4551 81.54 25.4756 81.7771 25.5054C84.5853 25.8863 86.8032 28.018 86.8032 30.9307V58.5156C86.8032 60.0162 86.8032 128.422 86.8032 126.09Z"
                    fill="#008080"
                  />
                  <path
                    d="M48.1927 126.09C48.1927 120.544 48.1927 33.3788 48.1927 30.9307C48.1927 30.3009 48.3018 29.7103 48.4927 29.1634C48.479 29.0412 48.4702 28.9173 48.4702 28.7913V27.3303C48.4702 27.3224 48.4702 27.3142 48.47 27.3063C48.4585 26.2694 47.5682 25.4551 46.5313 25.4551H43.1079C42.8626 25.4551 42.6225 25.4756 42.3854 25.5054C39.5772 25.8863 37.3594 28.018 37.3594 30.9307V58.5156C37.3594 69.5966 37.3594 96.6877 37.3594 107.591C37.3594 109.614 37.3594 123.819 37.3594 126.09C37.3594 129.267 39.9352 131.843 43.1128 131.843H53.9461C50.7688 131.843 48.1927 129.267 48.1927 126.09Z"
                    fill="#086B6B"
                  />
                  <path
                    d="M67.0845 27.2604H63.7513C62.6007 27.2604 61.668 26.3277 61.668 25.1771C61.668 24.0264 62.6007 23.0938 63.7513 23.0938H67.0845C68.2351 23.0938 69.1678 24.0264 69.1678 25.1771C69.1678 26.3277 68.2351 27.2604 67.0845 27.2604Z"
                    fill="#DDDDDD"
                  />
                  <path
                    d="M63.4712 123.926H60.138C58.9874 123.926 58.0547 122.994 58.0547 121.843C58.0547 120.692 58.9874 119.76 60.138 119.76H63.4712C64.6218 119.76 65.5545 120.692 65.5545 121.843C65.5545 122.994 64.6218 123.926 63.4712 123.926Z"
                    fill="#06C9AF"
                  />
                  <path
                    d="M56.6068 27.3685C55.4482 27.3685 54.5234 26.426 54.5234 25.2852C54.5234 24.2664 55.2565 23.4201 56.1985 23.2437C57.4938 22.9782 58.6901 23.9908 58.6901 25.2852C58.6901 26.3899 57.7954 27.3685 56.6068 27.3685Z"
                    fill="#DDDDDD"
                  />
                  <path
                    d="M74.0281 82.5377H61.806C60.6554 82.5377 59.7227 81.605 59.7227 80.4544C59.7227 79.3038 60.6554 78.3711 61.806 78.3711H74.0281C75.1787 78.3711 76.1114 79.3038 76.1114 80.4544C76.1114 81.605 75.1784 82.5377 74.0281 82.5377Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M74.0281 91.9821H61.806C60.6554 91.9821 59.7227 91.0494 59.7227 89.8987C59.7227 88.7481 60.6554 87.8154 61.806 87.8154H74.0281C75.1787 87.8154 76.1114 88.7481 76.1114 89.8987C76.1114 91.0494 75.1784 91.9821 74.0281 91.9821Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M74.0282 101.426H69.306C68.1553 101.426 67.2227 100.494 67.2227 99.3431C67.2227 98.1925 68.1553 97.2598 69.306 97.2598H74.0282C75.1789 97.2598 76.1116 98.1925 76.1116 99.3431C76.1116 100.494 75.1786 101.426 74.0282 101.426Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M74.0282 110.871H69.306C68.1553 110.871 67.2227 109.938 67.2227 108.787C67.2227 107.637 68.1553 106.704 69.306 106.704H74.0282C75.1789 106.704 76.1116 107.637 76.1116 108.787C76.1116 109.938 75.1786 110.871 74.0282 110.871Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M74.0268 63.9264H55.138C53.9874 63.9264 53.0547 62.9937 53.0547 61.8431C53.0547 60.6925 53.9874 59.7598 55.138 59.7598H74.0268C75.1774 59.7598 76.1101 60.6925 76.1101 61.8431C76.1101 62.994 75.1771 63.9264 74.0268 63.9264Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M74.0272 73.0934H57.3607C56.21 73.0934 55.2773 72.1607 55.2773 71.0101C55.2773 69.8594 56.21 68.9268 57.3607 68.9268H74.0272C75.1779 68.9268 76.1105 69.8594 76.1105 71.0101C76.1105 72.1607 75.1776 73.0934 74.0272 73.0934Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M71.1217 52.6765H52.7688C50.2431 52.6765 48.1953 50.629 48.1953 48.103V48.0833C48.1953 45.5575 50.2428 43.5098 52.7688 43.5098H71.1217C73.6474 43.5098 75.6952 45.5573 75.6952 48.0833V48.103C75.6952 50.6287 73.6474 52.6765 71.1217 52.6765Z"
                    fill="#06C9AF"
                  />
                  <path
                    d="M61.9451 48.0931C61.9451 45.5619 63.997 43.5098 66.5285 43.5098H52.7688C50.2431 43.5098 48.1953 45.5573 48.1953 48.0833V48.103C48.1953 50.629 50.2428 52.6765 52.7688 52.6765H66.5285C63.9973 52.6765 61.9451 50.6243 61.9451 48.0931Z"
                    fill="#06C9AF"
                  />
                  <path
                    d="M110.205 0.936633C94.2143 1.07226 81.1276 14.1021 80.9255 30.0919C80.8489 36.1565 82.6016 41.8068 85.6647 46.528C85.9225 46.9255 85.9688 47.4243 85.773 47.8558L80.4765 59.534C80.2197 60.1003 80.8019 60.6825 81.3679 60.4257L92.937 55.1784C93.4194 54.9597 93.9819 54.9947 94.4265 55.2826C99.1361 58.3331 104.769 60.0803 110.814 60.0095C126.815 59.8225 139.863 46.7303 139.998 30.7285C140.138 14.2126 126.721 0.796633 110.205 0.936633Z"
                    fill="#008080"
                  />
                  <path
                    d="M33.7491 66.9825C29.2308 66.9825 25.5547 63.3067 25.5547 58.7881C25.5547 54.2696 29.2308 50.5938 33.7491 50.5938C38.2673 50.5938 41.9434 54.2696 41.9434 58.7881C41.9434 63.3067 38.2676 66.9825 33.7491 66.9825ZM33.7491 55.1768C31.7579 55.1768 30.138 56.797 30.138 58.7879C30.138 60.7787 31.7582 62.3989 33.7491 62.3989C35.7402 62.3989 37.3601 60.7787 37.3601 58.7879C37.3601 56.797 35.7402 55.1768 33.7491 55.1768Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M27.6367 105.177V109.458C27.6367 112.833 30.3727 115.569 33.7478 115.569C37.1228 115.569 39.8588 112.833 39.8588 109.458V105.177H27.6367Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M33.7478 109.457V105.176H27.6367V109.457C27.6367 112.832 30.3727 115.568 33.7478 115.568C34.8612 115.568 35.9041 115.269 36.8032 114.749C34.9774 113.692 33.7478 111.719 33.7478 109.457Z"
                    fill="#F8A748"
                  />
                  <path
                    d="M56.7644 104.83C53.5701 103.847 51.2489 100.88 51.2489 97.3734V82.6261C51.2489 73.0318 43.399 65.1816 33.8044 65.1816H33.6934C24.099 65.1816 16.2489 73.0315 16.2489 82.6261V97.3734C16.2489 100.152 14.7912 102.589 12.5977 103.971V105.176H16.2489H51.2486H56.7641L56.7644 104.83Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M28.1932 97.3735V82.6262C28.1932 75.1064 33.0161 68.6596 39.7207 66.2227C28.4393 62.1222 16.2489 70.4787 16.2489 82.6262V97.3735C16.2489 100.152 14.7912 102.589 12.5977 103.972V105.176H16.2489H24.542V103.972C26.7355 102.589 28.1932 100.152 28.1932 97.3735Z"
                    fill="#F8A748"
                  />
                  <path
                    d="M18.283 51.1755C17.5631 51.1755 16.8628 50.802 16.477 50.1334L12.8772 43.8988C12.3018 42.9024 12.6431 41.6284 13.6398 41.0528C14.6362 40.4778 15.9104 40.8188 16.4857 41.8154L20.0852 48.0501C20.6606 49.0465 20.3193 50.3207 19.3226 50.896C18.9948 51.0852 18.6366 51.1755 18.283 51.1755Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M11.5939 57.862C11.2404 57.862 10.8822 57.7721 10.5541 57.5826L4.31941 53.9828C3.323 53.4075 2.98148 52.1333 3.55679 51.1368C4.1321 50.1402 5.40605 49.7989 6.40273 50.3742L12.6374 53.974C13.6338 54.5493 13.9753 55.8236 13.4 56.82C13.0142 57.4885 12.3139 57.862 11.5939 57.862Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M9.02781 66.9821H2.08332C0.932695 66.9821 0 66.0494 0 64.8988C0 63.7481 0.932695 62.8154 2.08332 62.8154H9.02781C10.1784 62.8154 11.1111 63.7481 11.1111 64.8988C11.1111 66.0494 10.1782 66.9821 9.02781 66.9821Z"
                    fill="#FAC149"
                  />
                  <path
                    d="M56.5289 107.259H10.9739C9.82332 107.259 8.89062 106.327 8.89062 105.176C8.89062 104.025 9.82332 103.093 10.9739 103.093H56.5292C57.6798 103.093 58.6125 104.025 58.6125 105.176C58.6125 106.327 57.6795 107.259 56.5289 107.259Z"
                    fill="#F8A748"
                  />
                  <path
                    d="M23.8903 105.176C23.8903 104.025 24.823 103.093 25.9736 103.093H10.9739C9.82332 103.093 8.89062 104.025 8.89062 105.176C8.89062 106.327 9.82332 107.259 10.9739 107.259H25.9739C24.823 107.259 23.8903 106.327 23.8903 105.176Z"
                    fill="#EF8C29"
                  />
                  <path
                    d="M97.0578 34.277C95.094 34.277 93.4922 32.6776 93.4922 30.7114C93.4922 28.6392 95.2794 26.9562 97.4086 27.1627C99.3413 27.3557 100.8 29.074 100.606 31.0622C100.424 32.8819 98.8909 34.277 97.0578 34.277Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M111.011 34.2769C109.16 34.2769 107.642 32.8616 107.463 31.0621C107.269 29.0849 108.724 27.3565 110.66 27.1626C112.78 26.957 114.577 28.6342 114.577 30.7113C114.577 32.6398 113.009 34.2769 111.011 34.2769Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M124.96 34.277C122.961 34.277 121.395 32.6394 121.395 30.7114C121.395 28.8807 122.786 27.3451 124.609 27.1627C126.728 26.9571 128.526 28.6239 128.526 30.7114C128.526 32.6741 126.931 34.277 124.96 34.277Z"
                    fill="#EFEFEF"
                  />
                  <path
                    d="M95.0381 38.2838C88.6698 27.4655 89.5152 14.4305 96.0816 4.7002C87.1626 9.71121 81.0627 19.2162 80.9255 30.0921C80.8489 36.1567 82.6016 41.807 85.6647 46.5282C85.9225 46.9258 85.9688 47.4245 85.773 47.856L80.4765 59.5343C80.2197 60.1006 80.8019 60.6827 81.3679 60.4259L92.937 55.1787C93.4194 54.9599 93.9819 54.9947 94.4265 55.2829C99.1361 58.3333 104.769 60.0806 110.814 60.0098C117.997 59.9258 124.584 57.2401 129.676 52.8591C116.522 56.075 102.213 50.4725 95.0381 38.2838Z"
                    fill="#086B6B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1967_10281">
                    <rect width="140" height="140" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <p className="mx-4 text-lg text-kGreen-light font-bold">
                OTP Verification
              </p>
              <p className="text-center  mx-4 text-gray-500">
                Please enter your OTP. An OTP was sent to your number. Please
                don’t share the OTP with anyone.
              </p>
              <div className="h-4"></div>

              <OtpField
                otpEnterDone={(otp) => {
                  handleVerifyLogin(otp);
                }}
              />
              <div className="h-4"></div>

              {/*fix bottom button  Next */}
              <div className="flex justify-center items-center fixed bottom-4 ">
                <button className="bg-teal-700 hover:bg-teal-500 text-white font-bold py-2 px-8 rounded-md">
                  Next
                </button>
              </div>
            </>
          }
          onSuccess={(data) => {
            return (
              <AlertPrimary type="success">
                Otp is SucessFully verified
              </AlertPrimary>
            );
          }}
        />
      </section>
    </>
  );
};

export default OtpVerificationPage;

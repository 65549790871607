

import { ErrorMessage, Field, FieldProps } from 'formik';
import { FC } from 'react';
import { validateRequired } from './FormikItem';

export interface ISelectFormikProps {
    label: string
    name: string
    optionLabelWithValue?: string[]
    optionLabel?: string[]
    optionValue?: string[]
    required?: boolean
    placeholder?: string
}
export const SelectFormik: FC<ISelectFormikProps> = ({ label, name, optionLabelWithValue, optionLabel, optionValue, required, placeholder }) => {





    return (
        <Field name={name} validate={validateRequired}>
            {(e: FieldProps) => (
                <div className='my-4'>
                    <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">{label}{required && <span className='text-red-500 ml-1'>*</span>}</label>
                    {/* <Select color={'success'} placeholder={placeholder} id={name} {...e.field}>
                        <option disabled selected value={''}>{placeholder ?? `Select ${name}`}</option>
                        {optionLabelWithValue ? optionLabelWithValue.map((item, i) => <option value={item} key={i}>{item}</option>) : optionValue!.map((e, i) => <option value={e}>{optionLabel![i]}</option>)}


                    </Select> */}
                    <select placeholder={placeholder} id={name} {...e.field} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option disabled selected value={''}>{placeholder}</option>
                        {optionLabelWithValue ? optionLabelWithValue.map((item, i) => <option value={item} key={i}>{item}</option>) : optionValue!.map((e, i) => <option value={e}>{optionLabel![i]}</option>)}
                    </select>
                    <ErrorMessage name={name}>
                        {(msg) => <p className='text-red-500'>{msg}</p>}
                    </ErrorMessage>
                </div>)
            }
        </Field>
    )
}



// Abdus Samad - 200,
// Rubel - 300
// Bappi - 200
// Mustafuz - 200
// Sahabul - 200
// 
// --------------
// 1450
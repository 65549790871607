import { FC } from "react";
import SheetNavLayout from "../../../components/Layouts/SheetNavLayout";
import { OfferListTile } from "./../components/OfferListTile";

export const OfferPagerouteName = "offer--route";

interface IOfferPageProps {}

const OfferPage: FC<IOfferPageProps> = ({}) => {
  return (
    <SheetNavLayout>
      <div className="p-4 border-b-2">
        <p className="font-bold">What can we help you with ?</p>
      </div>
      <section className="m-4 flex-col space-y-2">
        <OfferListTile
          title={"Digital Loans"}
          content={"Borrow loan whenever you need"}
        />
        <OfferListTile
          title={"Buy Now Pay Later"}
          content={"Avail attractive BNPL products for you"}
        />
        <OfferListTile
          title={"Credit Cards"}
          content={"Credit cards handpicked for you "}
        />
        {/* <OfferListTile
          title={"Rewards"}
          content={"Explore discounts, jackpot and cashback offers"}
        /> */}
      </section>
    </SheetNavLayout>
  );
};
export default OfferPage;

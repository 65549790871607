import axios, { AxiosRequestConfig, CreateAxiosDefaults } from "axios";
import { API_URL } from "../../constants";
import useBoundStore from "../../state/store";
import  useSWRImmutable  from 'swr/immutable';
import  useMutation  from 'swr/mutation';
import  useSWR, { SWRResponse }  from 'swr';
import { Key } from "react";


const config:CreateAxiosDefaults<any> = {
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    }
}

export const axiosInstansce = axios.create(config)


export const useAxios = <T>(url:string,cfig?:AxiosRequestConfig<T>, ) => {
    const token = useBoundStore(state => state.userInfo?.token);
    const config:AxiosRequestConfig= cfig || {
        url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

    };
    const req =  axiosInstansce.request<T>({
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        },
    }).then(res => {
        console.log(res.data,"Response Data");
        
        const resPonse = res.data as {data:any,error:boolean,message:string};

        if(resPonse?.error){
            throw new Error(resPonse?.message);
        }
        return resPonse?.data;
    });

    const requestFunction =<T> (key: string|Key,{arg}:{arg:{
        rconfig?: AxiosRequestConfig<T>,
   
    }})=>{

        return axiosInstansce.request<T>({
            ...arg.rconfig,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
            url: key as string
        }).then(res => {
            console.log(res.data,"Response Data");
            
            const resPonse = res.data as {data:any,error:boolean,message:string};

            if(resPonse?.error){
                throw new Error(resPonse?.message);
            }
            return resPonse?.data;
        });
        
    }

    return {
        req,
        requestFunction,
        requestMultipart: (data:FormData) => axiosInstansce.request<T>({
            ...config,
            method: "POST",
            data,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            },
        }).then(res => {
            console.log(res.data,"Response Data");
            
            const resPonse = res.data as {data:any,error:boolean,message:string};

            if(resPonse?.error){
                throw new Error(resPonse?.message);
            }
            return resPonse?.data;
        }), 
}
}

export const useAxiosAuto = <T>(url:string,config?:AxiosRequestConfig) => {
    const {req} = useAxios<T>(url,config); 
    return useSWR(url,()=>req);
}
export const useAxiosManual = <T>(url:string,config?:AxiosRequestConfig) => {
    const {requestFunction} = useAxios<T>(url,config); 
    return useMutation(url,requestFunction);
}
export const useAxiosCache = <T>(url:string,config?:AxiosRequestConfig) => {
    const {requestFunction} = useAxios<T>(url,config); 
    return useSWRImmutable(url,requestFunction);
}


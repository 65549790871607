import { TextInput } from "flowbite-react";
import { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import { useRequestForMutation } from "../../../modules/hook/useRequest";
import BtnPrimary from "../../../modules/shared/BtnPrimary";
import Loading from "../../../modules/shared/Loading";
import { RouterItemEnum } from "../../../router/path";
import useBoundStore from "../../../state/store";
import { ReactComponent as EmailIcon } from "../email.svg";
import { ReactComponent as NameIcon } from "../input-name.svg";

export interface IProfileInputProps {}
export const ProfileInputrouteName = "/profile-input";
const ProfileInput: FC<IProfileInputProps> = ({}) => {
  const { profileInfo } = useBoundStore();
  const nameInp = useState(profileInfo?.name ?? "");
  const emailInp = useState(profileInfo?.email ?? "");
  const [showName, setShowName] = useState(true);
  const toggle = () => {
    setShowName(!showName);
  };
  const navigator = useNavigate();
  const { trigger, isMutating } = useRequestForMutation("/user");

  return (
    <TitlePageLayout
      title="Provide Your Info"
      dontShowBottomNav
      showBackButton={false}
    >
      <div className="h-[15vh]"></div>
      {showName ? (
        <MakeInputField
          message={"Input your name same as your government approved ID."}
          icon={<NameIcon />}
          state={nameInp}
        />
      ) : (
        <MakeInputField
          message={"Input your email address."}
          icon={<EmailIcon />}
          state={emailInp}
        />
      )}

      <div className="h-[10vh]"></div>
      {isMutating ? (
        <Loading />
      ) : (
        <div className="flex items-center justify-center">
          {!showName ? (
            <button
              className="btn-out btn-out-pri"
              onClick={() => navigator(-1)}
            >
              Skip
            </button>
          ) : undefined}

          <div className="w-10"></div>
          <BtnPrimary
            disable={nameInp[0].length < 4}
            onClick={() => {
              if (showName) {
                toggle();
              } else {
                trigger(
                  {
                    postBody: {
                      name: nameInp[0],

                      email: emailInp[0],
                    },
                  },
                  {
                    onSuccess() {
                      navigator(RouterItemEnum.home, {
                        replace: true,
                      });
                    },
                  }
                );
              }
            }}
          >
            {showName ? "Next" : "Submit"}
          </BtnPrimary>
        </div>
      )}
    </TitlePageLayout>
  );
};
export default ProfileInput;

export const MakeInputField: FC<{
  message: string;
  icon: ReactNode;
  state: [string, Dispatch<SetStateAction<string>>];
}> = ({ message, icon, state }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      {icon}
      <div className="h-10"></div>
      <div className="flex flex-col justify-center px-10 w-full">
        <p className="text-gray-500 text-center">{message} </p>
        <div className="h-10"></div>
        <TextInput
          value={state[0]}
          onChange={(e) => state[1](e.target.value)}
        />
      </div>
    </div>
  );
};

import { lazy } from "react";
import { RouterItemEnum } from "../../../router/path";
import HoldForParsing, {
  HoldForParsingrouteName,
} from "../pages/HoldForParsing";
const CreditLimitPage = lazy(() => import("../pages/CreditLimit"));
const Psycometric = lazy(() => import("../pages/Psycometric"));
const VariableFormHolder = lazy(() => import("../pages/VariableDependcy"));

export const scoreRoutes = [
  {
    path: "new",
    element: <VariableFormHolder />,
  },

  {
    path: RouterItemEnum.Psycometric,

    element: <Psycometric />,
  },
  {
    path: HoldForParsingrouteName,
    element: <HoldForParsing />,
  },
  {
    path: RouterItemEnum.CreditLimitShow,

    element: <CreditLimitPage />,
  },
];

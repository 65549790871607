import { FC, PropsWithChildren, useState } from 'react';
import { ReactComponent as SucessIcon } from '../assets/success.svg'
import { ReactComponent as ErrorIcon } from '../assets/close.svg'
export interface IAlertPrimaryProps extends PropsWithChildren {
    icon?: React.ReactNode
    type?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info' | 'light' | 'dark'
    canClose?: boolean
}

const AlertPrimary: FC<IAlertPrimaryProps> = ({ children, type, canClose = false }) => {
    const [showAlert, setShowAlert] = useState(true)
    if (!showAlert) return null

    return (
        <div className="flex items-center p-2 mb-4 text-sm  bg-kGreen-light/40 rounded-lg dark:bg-gray-800 dark:text-blue-400" role="alert">
            {type === 'success' && <SucessIcon />}
            {type === 'error' && <ErrorIcon />}
            <div className='ml-2'>
                {children}
            </div>
            {canClose && <button onClick={() => setShowAlert(!showAlert)} type="button" className="ml-auto -mx-1.5 -my-1.5  text-pink-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-1" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>}

        </div>
    );
}
export default AlertPrimary

import useSWR from "swr";
import { useGetHttpClient } from "../../../client/axios";
import { IUserConfig } from "../../../components/hooks/useConfig";
import { ICommonUserStatus } from "../../../modules/score/types";
import useBoundStore from "../../../state/store";
import { IProfileMain } from "../../Profile/types";

export const useFetchUserScore = () => {
  const { setUserStaus, setProfileInfo, setUserConfig } = useBoundStore();
  const client = useGetHttpClient();

  return useSWR(
    "fetchUserinfo",
    async () => {
      const userStatus = await client<ICommonUserStatus>("/user/status");
      const usrConfigStatus = client<IUserConfig>("/config/user");
      const usrProfileStatus = client<IProfileMain>("/user");

      try {
        const [usrConfigData, usrProfileData] = await Promise.all([
          usrConfigStatus,
          usrProfileStatus,
        ]);
        return {
          userStatus,
          usrConfigData,
          usrProfileData,
        };
      } catch (error: any) {
        if (error instanceof Error) {
          throw new Error(error.message);
        }
        throw new Error(error);
      }
    },
    {
      onSuccess({ userStatus, usrConfigData, usrProfileData }) {
        setUserStaus(userStatus);
        setProfileInfo(usrProfileData);
        setUserConfig(usrConfigData);
      },
      focusThrottleInterval: 30000,
      dedupingInterval: 5000,
    }
  );
};

import { Link, useNavigate } from "react-router-dom";
import ShowIf from "../../../directive/ShowIf";
import useBoundStore from "../../../state/store";
import { AppBar } from "./AppBar";
import { NewScoreCircle } from "./ScoreCircle";
export function Score() {
  const { useStatus, profileInfo } = useBoundStore();
  const nav = useNavigate();

  const score = useStatus?.score ?? 0;

  const handleScoreNavigation = () => {
    if (!Boolean(useStatus?.can_rescore)) {
      return;
    }

    nav("/score/new");
  };

  return (
    <section className="h-[50vh] relative text-white flex items-center justify-center bg-gradient-to-r from-purple-600 via-blue-900 to-purple-800">
      <AppBar uname={profileInfo?.name ?? ""} />

      <ShowIf
        condition={score !== 0}
        fallback={
          <Link to={"/score/new"}>
            <div className=" h-[65vw] w-[65vw] bg-black opacity-30 rounded-full flex items-center justify-center relative">
              <div className=" h-[60vw] w-[60vw] rounded-full border-4 border-green-600 flex items-center justify-center absolute  ">
                <p className="text-3xl font-bold text-center m-3">
                  Build Affordability Score
                </p>
              </div>
            </div>
          </Link>
        }
      >
        <NewScoreCircle
          handleScoreNavigation={handleScoreNavigation}
          score={score}
          score_comment={useStatus!.score_comment}
          lastUpdate={useStatus!.last_updated}
        />
        {/* <ScoreCircle
          handleScoreNavigation={handleScoreNavigation}
          score={score}
          score_comment={useStatus!.score_comment}
          lastUpdate={useStatus!.last_updated}
        /> */}
      </ShowIf>
    </section>
  );
}



export const enum RouterItemEnum {
    home = "/home",
    Rescore = "rescore",
    error = "/error",
    // loan pages
    loan = "/loan",
    loanprocess = "process",
    LoanAccept = "accept",
    History = "history",
    //score
    Score = "/score",
    CreditLimitShow = "credit-limit-show",
    UserInfo = "user-info",
    MyScore = "my-score",
    Psycometric = "psychometric",
    Congratulation = 'congratulation',
    // common pages
    common = "/common",
    thankYou = "thanks",
    Info = "info-details",
    main = "main",
    success = "success",

    // Earned Wage
    earnedWage = "/earnWaged",
    earnWagedProcess = "process",
    sod = '/sod',



    // PayLater
    payLater = "/payLater",
    ProductDetails = "product/:proid",
    allProduct = "allProduct",
    Applicatio = "application",
    OverView = "over-view",
    DevivaryAddress = "devivary-address",
    DevilaryOverview = "devivary-prod-overview",
    PayLaterCongratulation = "payLater-congratulation",
    OrderHistory = 'orders',
    OrderHistoryDetails = 'order/:id',
    TrackOrder = 'track-order/:id',
    RegionWarn = 'region-warning',
    FilterProduct = 'filter-product/:id',
    PaylaterAgreement = 'agreement',




    //Reward
    RewardHome = '/reward',
    DiscountOffer = 'discount-offer',
    CashReward = 'cash-reward',
    JackPot = 'jackpot',
    HowItWorks = 'how-it-works',
    PointDescription = 'point-description',



    //ekyc
    Ekyc = "/ekyc",
    EkycBnpl = '/ekyc?ctx=bnpl',
    EkycCrard = '/ekyc?ctx=credit-card',
    EkycLoan = '/ekyc?ctx=cash',
    EkycSod = '/ekyc?ctx=sod',
    NIDFont = "nid-font",
    NIDBack = "nid-back",
    FacialVerification = "facial-verification",
    VerifyInfo = "verify-info",
    OneMoreStep = "one-more-step",
    AddressVerificationPage = "verify-address",
    addPhone = "add-phone-number",

    // Account
    Account = "/account",
    Select = "select-account",
    AddBank = "add-bank-info",
    AddWallet = "add-wallet-info",
    Disbursed = "disbursed-details",
    // Dana Host App
    notification = "/notification",
    stateMent = "/statements",
    profile = "/profile",
    More = '/more',
    PaymentSechudle = '/payment',
    HowItWorksDana = '/howitwork',
    WhatisCreditScore = '/whatisCreditScore',
    CreditScoreBuilder = '/credit-score',
    ColorPicker = "/color-pick",
    Test = "/test",
    ProfileInput = '/profileInput'


}



export const makeRoute = (routes: string[]): string => {
    if (routes.length === 1) return routes[0]
    return routes.join("/")
}
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { scoreRoutes } from "../modules/score/routes/route";
import HolderPage from "../pages/HolderPage";
import NewHome from "../pages/NewHome";
import ErrorPage from "../pages/error/Error";
import NotFoundErrorPage from "../pages/error/Error/404";
import { commonRoutes } from "./common";
import { RouterItemEnum } from "./path";
const ThankYou = lazy(() => import("../modules/shared/ThankYou"));
const HomePage = lazy(() => import("../pages/Home"));

const router = createBrowserRouter([
  {
    path: "/",

    errorElement: <ErrorPage />,

    children: [
      {
        index: true,
        element: <HolderPage />,
      },
      {
        path: "/home",

        element: <NewHome />,
      },
      // {
      //   path: "/test",

      //   element: <TestPage />,
      // },
      // // *? Loan Routes
      // ...loanRouters!,
      // // ** Paylater Routes
      // {
      //   path: RouterItemEnum.payLater,
      //   children: paylaterRoutes,
      // },
      // {
      //   path: RouterItemEnum.sod,
      //   children: sodRoutes,
      // },
      // *! CreditCard Routes
      // {
      //   path: RouterCreditCard.CreditCard,
      //   children: creditCardRoutes,
      // },
      // ** Score Page
      {
        path: RouterItemEnum.Score,
        children: scoreRoutes,
      },

      // *! Account Routes
      // {
      //   path: RouterItemEnum.Account,
      //   children: [
      //     {
      //       index: true,
      //       element: <AccountMain />,
      //     },
      //     makePath(RouterItemEnum.AddBank, <AddBank />),
      //     makePath(
      //       RouterItemEnum.AddWallet,

      //       <AddWallet />
      //     ),
      //   ],
      // },

      // EKYC

      // {
      //   path: RouterItemEnum.Ekyc,
      //   children: EKYCRoutes,
      // },
      // {
      //   path: RouterItemEnum.common,
      //   element: <BottomButtonLayout />,
      //   children: [
      //     {
      //       path: RouterItemEnum.thankYou,
      //       element: <ThankYou />,
      //     },
      //   ],
      // },
      //Reward
      // {
      //   path: RouterItemEnum.RewardHome,
      //   children: rewardRoutes,
      // },

      // ETC PATH
      ...commonRoutes,
    ],
  },

  {
    path: "*",
    element: <NotFoundErrorPage />,
  },
]);

export default router;

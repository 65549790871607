import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { placeOrder } from '../../../paylater/service';

export interface IFileInputFormikProps {
    label: string
    name: string,
    placeholder?: string,
    required?: boolean

}

const FileInputFormik: FC<IFileInputFormikProps> = ({ name, label, placeholder, required }) => {
    return (
        <div>
            <Field name={name}>
                {(f: FieldProps) => <>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={name}>{label}</label>
                    <input onChange={e => {
                        e.currentTarget.files && f.form.setFieldValue(name, e.currentTarget.files[0])
                    }} placeholder={placeholder} required={required} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id={name} type="file" />
                </>}
            </Field>


        </div>
    );
}
export default FileInputFormik
